<template>
  <div class='col-12 col-lg-6 g c'>
    <br>
    <div class="card card-body">
        <div class="col-12 text-center">
            <h4>
                برجاء تسجيل الدخول لحساب مدرستي واختيار المدرسة لتتعرف الاداة على المدرسة المراد التعامل معها.
                <br><br>
                او يمكنك الدخول للاداة عن طريق منصة مدرستي مباشرة.
                <br><br>
                هل لديك اي مشكلة؟
                <a href="/contact" class="btn btn-primary">
                تواصل معنا الآن</a>
            </h4>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    created(){
        if(localStorage.getItem('school_id')){
            window.location = '/'
        }
    }
}
</script>

<style>

</style>